import * as React from "react"
import { useEffect, useState } from 'react';
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"
import sessions from "../../data/sessions.json"
import * as helper from './../helper/utils.js';
import * as statusHelper from './../helper/status.js';
import { Badge } from 'react-bootstrap';
import { Card } from 'react-bootstrap';

import { ApplicationInsights } from '@microsoft/applicationinsights-web'
const appInsights = new ApplicationInsights({ config: {
  connectionString: 'InstrumentationKey=3ed836c2-57aa-4d4b-827f-b73b688216fc;IngestionEndpoint=https://westeurope-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/'
} });
appInsights.loadAppInsights();
appInsights.trackPageView();

const IndexPage = () => {
  const [selectedSessions, setSelectedSessions] = useState([]);

  const TotalBadges = (props) => {
    
    let sessionList = [];
    if(props.selection === 'all') { sessionList = sessions.data; }
    if(props.status && selectedSessions) {  
      sessionList = selectedSessions.map(t1 => ({...t1, ...sessions.data.find(t2 => t2.id === t1.id)}))
    }
  
    const TrackBadge = (props) => (
      (props.status)
      ?
      <React.Fragment><Link to={`sessionlist?track=${props.item.id}${(props.status) ? '&status=' + props.status: ''}`}><Badge size="sm" bg={props.item.id}>{props.item.label_en} ({sessionList.filter(x => x.track?.id === props.item.id && x.status == props.status).length})</Badge></Link>&nbsp;</React.Fragment>      
      :
      <React.Fragment><Link to={`sessionlist?track=${props.item.id}`}><Badge size="sm" bg={props.item.id}>{props.item.label_en} ({sessionList.filter(x => x.track?.id === props.item.id).length})</Badge></Link>&nbsp;</React.Fragment>
    )

    const TINCONBadge = (props) => (
      (props.status)
      ?
      <React.Fragment><Link to={`sessionlist?special=TINCON${(props.status) ? '&status=' + props.status: ''}`}><Badge size="sm" bg="TINCON">TINCON ({sessionList.filter(x => x.subconference?.id === "tincon-x-re-publica-22" && x.status == props.status).length})</Badge></Link>&nbsp;</React.Fragment>
      :
      <React.Fragment><Link to={`sessionlist?special=TINCON`}><Badge size="sm" bg="TINCON">TINCON ({sessionList.filter(x => x.subconference?.id === "tincon-x-re-publica-22").length})</Badge></Link>&nbsp;</React.Fragment>
    )

    const TxtBadge = (props) => (
      (props.status)
      ?
      <React.Fragment><Link to={`sessionlist?special=txt${(props.status) ? '&status=' + props.status: ''}`}><Badge size="sm" bg="txt">.txt ({sessionList.filter(x => x.subconference?.id === "-txt-long-live-the-word-" && x.status == props.status).length})</Badge></Link>&nbsp;</React.Fragment>
      :
      <React.Fragment><Link to={`sessionlist?special=txt`}><Badge size="sm" bg="txt">.txt ({sessionList.filter(x => x.subconference?.id === "-txt-long-live-the-word-").length})</Badge></Link>&nbsp;</React.Fragment>
    )    
    
    return (
      <div>
      {helper.topics.map((item, i) => (
        <React.Fragment key={item.id}>
          <TrackBadge item={item} status={props.status}></TrackBadge>                  
        </React.Fragment>
      ))}
      
    </div>
  )}


  useEffect(() => {
    // 
    console.log('sessions.data.length', sessions.data.length);
    const result = sessions.data.map(item => item.track.id)
    .filter((value, index, self) => self.indexOf(value) === index)
  
    console.log('result', result);

    let local = [];
    if (typeof window !== `undefined`) {
      local = JSON.parse(window.localStorage.getItem('selectedSessions'));
    }
    let exportString = '';
    local?.filter(x => x.status == 1)?.map(x => exportString += x.id + ',');

    setSelectedSessions(local);
    
  }, []);

  return(
  <Layout>
    <Seo title="Start" />
    <div className={styles.textCenter}>
      <h1>Welcome to <b>rp23 List Builder</b></h1>
      <h2><Badge>Total ({sessions.data.length})</Badge> </h2>
      <TotalBadges selection="all"></TotalBadges>
      <p></p>
      <h2><Link to="schedule"><Badge>Your list ({(selectedSessions?.length) ? (selectedSessions.length) : 0})</Badge></Link></h2>
      <p></p>

      <Card>
        <Card.Title>
          <p></p><Link to="sessionlist?status=1"><Badge bg="success">Yes ({(selectedSessions?.length) ? (selectedSessions.filter(x => x.status == 1).length) : 0})</Badge></Link></Card.Title>
        <Card.Subtitle >Oh so very much!</Card.Subtitle>
        <Card.Body>        
          <TotalBadges status="1"></TotalBadges>
        </Card.Body>
      </Card>      
      <p></p>       

      <Card>
        <Card.Title>
          <p></p><Link to="sessionlist?status=4"><Badge bg="info">Interested ({(selectedSessions?.length) ? (selectedSessions.filter(x => x.status == 4).length) : 0})</Badge></Link>
        </Card.Title>
        <Card.Subtitle>Aha!</Card.Subtitle>
        <Card.Body>        
          <TotalBadges status="4"></TotalBadges>
        </Card.Body>
      </Card>      
      <p></p>    
      <Card>
        <Card.Title>
          <p></p><Link to="sessionlist?status=3"><Badge bg="warning">Unsure ({(selectedSessions?.length) ? (selectedSessions.filter(x => x.status == 3).length) : 0})</Badge></Link>
        </Card.Title>
        <Card.Subtitle>Who knows?</Card.Subtitle>
        <Card.Body>        
          <TotalBadges status="3"></TotalBadges>
        </Card.Body>
      </Card>      
      <p></p>
      <Card>
        <Card.Title>
          <p></p><Link to="sessionlist?status=2"><Badge bg="secondary">No ({(selectedSessions?.length) ? (selectedSessions.filter(x => x.status == 2).length) : 0})</Badge></Link>
        </Card.Title>
        <Card.Subtitle>Better not!</Card.Subtitle>
        <Card.Body>        
          <TotalBadges status="2"></TotalBadges>
        </Card.Body>
      </Card>
    
      
    <p></p>  
      Use this page to decide which rp23 sessions you are interested in.<br></br>
      It should help you to plan ahead, add the sessiont to the <a href="https://apps.apple.com/de/app/republica/id869014061">conference app</a> or follow up later.<br></br>      
      <p></p>
      Loading your selection on other devices <Link to="/share">is as simple as name your selection</Link>.<br></br>
      <p></p>
      Remark: This is a "last minute" adoption of last year's session picker. Please apologize any inconviences. I try to fix errors as they are recognized and reported but will mainly enjoy my stay at rp23.
    </div>          
  </Layout>
)}

export default IndexPage
